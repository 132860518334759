a {
    display: inline-block;
    transition: color 0.2s ease;
    &:hover {
    	color: @color-green;
    }
}
img {
	max-width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	padding: 0px; margin: 0px;
}

#cookies {
    position: fixed;
    width: 100%;
    overflow: hidden;
    background: #dedede;
    font-size: 12px;
    box-sizing: border-box;
    z-index: 9999;
    bottom: 0;
    left: 0;
    text-align: center;
    p {
        margin: 5px 0;
    }
    .button {
        background: #888;
        color: white;
        padding: 2px 5px;
        margin-left: 10px;
        border: none;
        font-size: 12px;
    }
    .deny-button {
        position: absolute;
        right: 20px;
        bottom: 10px;
        border: 0;
        opacity: 0.5;
        color: contrast(#454545);
    }
}