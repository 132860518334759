@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&subset=latin-ext');

@import './normalize.less';
@import './reset.less';
@import './general.less';

@color-green: #009118;

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.flex-wrap(@wrap) {
    -webkit-flex-wrap: @wrap;
    -moz-flex-wrap: @wrap;
    -ms-flex-wrap: @wrap;
    -o-flex-wrap: @wrap;
    flex-wrap: @wrap;
}

.clear {
    &:after {
        content: "";
        display: block;
        height: 0px;
        width: 0px;
        clear: both;
    }
}

.inner-content {
    margin: auto;
    max-width: 1500px;
    @media screen and (max-width: 1550px) {
        padding: 0px 40px;
    }
    @media screen and (max-width: 1050px) {
        padding: 0px 25px;
    }
    @media screen and (max-width: 500px) {
        padding: 0px 15px;
    }
    @media screen and (max-width: 400px) {
        padding: 0px 5px;
    }
    &.thin {
        max-width: 1150px;
        @media screen and (max-width: 1200px) {
            padding: 0px 40px;
        }
        @media screen and (max-width: 1050px) {
            padding: 0px 25px;
        }
        @media screen and (max-width: 500px) {
            padding: 0px 15px;
        }
        @media screen and (max-width: 400px) {
            padding: 0px 5px;
        }
    }
    &.wider {
        max-width: 1500px;
        @media screen and (max-width: 1550px) {
            padding: 0px 40px;
        }
        @media screen and (max-width: 1050px) {
            padding: 0px 25px;
        }
        @media screen and (max-width: 500px) {
            padding: 0px 15px;
        }
        @media screen and (max-width: 400px) {
            padding: 0px 5px;
        }
    }
}

html, body {
    font-family: 'Poppins', sans-serif;
    padding: 0px; margin: 0px;
    color: #383838;
    font-size: 16px;
    background-color: #eff3f6;
    @media screen and (max-width: 1200px) {
        font-size: 15px;
    }
    @media screen and (max-width: 1150px) {
        font-size: 14px;
    }
    @media screen and (max-width: 1000px) {
        font-size: 13px;
    }
    @media screen and (max-width: 650px) {
        font-size: 12px;
    }
}

#header {
    background-image: url('../assets/header-background.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
    position: relative;
    // min-height: 700px;
    min-height: 250px;
    padding-bottom: 150px;
    @media screen and (max-width: 800px) {
        padding-bottom: 100px;
    }
    @media screen and (max-width: 650px) {
        padding-bottom: 80px;
    }
    &.mobileToggled {
        .top {
            .menu {
                nav {
                    transform: scaleX(1);
                }
            }
        }
    }
    .top {
        width: 100%;
        padding-top: 50px;
        @media screen and (max-width: 1450px) {
            padding-top: 25px;
        }
        @media screen and (max-width: 950px) {
            padding-top: 10px;
        }
        @media screen and (max-width: 550px) {
            padding-top: 0px;
        }
        .clear;
        .logo {
            float: left;
            a {
                display: inline-block;
                img {
                    @media screen and (max-width: 1050px) {
                        max-width: 250px;
                        padding-top: 5px;
                    }
                    @media screen and (max-width: 550px) {
                        max-width: 180px;
                        padding-top: 10px;
                    }
                }
            }
        }
        .menu {
            float: right;
            align-items: center;
            .flex;
            .clear;
            nav {
                float: left;
                @media screen and (max-width: 1250px) {
                    width: 100%;
                    padding-top: 25px;
                }
                @media screen and (max-width: 950px) {
                    z-index: 1000;
                    position: absolute;
                    top: 65px; right: 0px;
                    padding-right: 25px;
                    transform: scaleX(0);
                    transition: all 0.4s ease;
                    transform-origin: right;
                    width: auto;
                    background-color: @color-green;
                    padding: 20px;
                    a {
                        display: block;
                        font-size: 1.3rem;
                        padding: 5px 5px;
                    }
                }
                @media screen and (max-width: 550px) {
                    top: 55px;
                }
                a {
                    font-size: 1.2rem;
                    font-weight: 400;
                    color: #fff;
                    padding: 3px 5px;
                    margin: 0px 10px;
                    &:hover {
                        color: black;
                    }
                    @media screen and (max-width: 1450px) {
                        margin: 0px 5px;
                        padding: 3px 2px;
                    }
                }
            }
            .kontakt {
                float: right;
                @media screen and (max-width: 1250px) {
                    position: absolute;
                    top: 10px; right: 45px;
                }
                @media screen and (max-width: 1050px) {
                    right: 30px;
                }
                @media screen and (max-width: 950px) {
                    top: 25px; right: 110px;
                }
                @media screen and (max-width: 650px) {
                    right: 90px;
                }
                @media screen and (max-width: 550px) {
                    left: 0px;
                    top: 55px;
                    z-index: 500;
                }
                a {
                    color: #fff;
                    font-size: 1.8rem;
                    font-weight: bold;
                    padding-left: 55px;
                    position: relative;
                    transition: all 0.4s ease;
                    @media screen and (max-width: 650px) {
                        padding-left: 45px;
                    }
                    &:hover {
                        color: black;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        left: 15px; top: 5px; bottom: 5px;
                        width: 40px; height: auto;
                        background-image: url('../assets/phone-contact.png');
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-size: contain;
                    }
                }
            }
            .mobile-menu-button {
                display: none;
                @media screen and (max-width: 950px) {
                    display: inline-block;
                    position: relative;
                    width: 35px;
                    top: 15px;
                    height: 30px;
                    cursor: pointer;
                    right: 10px;
                    span {
                        background-color: #fff;
                        height: 3px;
                        top: 14px;
                        border-radius: 2px;
                        position: absolute;
                        left: 0px;
                        width: 100%;
                    }
                    &:before {
                        content: '';
                        background-color: #fff;
                        height: 3px;
                        top: 2px;
                        border-radius: 2px;
                        position: absolute;
                        left: 0px;
                        width: 100%;
                    }
                    &:after {
                        content: '';
                        background-color: #fff;
                        height: 3px;
                        bottom: 2px;
                        border-radius: 2px;
                        position: absolute;
                        left: 0px;
                        width: 100%;
                    }
                }
            }
        }
    }
    .main {
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 50px;
        padding-bottom: 30px;
        @media screen and (max-width: 550px) {
            padding-top: 70px;
        }
        .moto {
            color: #fff;
            max-width: 550px;
            h1 {
                font-size: 3.3rem;
                line-height: 1.2;
                @media screen and (max-width: 1450px) {
                    font-size: 3rem;
                }
                @media screen and (max-width: 1050px) {
                    font-size: 2.8rem;
                }
                @media screen and (max-width: 650px) {
                    font-size: 2.6rem;
                }
                @media screen and (max-width: 350px) {
                    font-size: 2.2rem;
                }
            }
            h2 {
                font-size: 1.7rem;
                line-height: 1.3;
                font-weight: 300;
                padding-top: 5px;
                @media screen and (max-width: 1450px) {
                    font-size: 1.6rem;
                }
                @media screen and (max-width: 650px) {
                    font-size: 1.5rem;
                }
                @media screen and (max-width: 350px) {
                    font-size: 1.4rem;
                }
            }
            a {
                font-weight: 700;
                font-size: 1.2rem;
                padding-top: 5px;
                &:hover {
                    color: #fff;
                }
            }
        }
    }
    &.subpage {
        padding-bottom: 125px;
        // background: #009719;
        // background: -moz-linear-gradient(left, #009719 0%, #02c522 100%);
        // background: -webkit-linear-gradient(left, #009719 0%,#02c522 100%);
        // background: linear-gradient(to right, #009719 0%,#02c522 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009719', endColorstr='#02c522',GradientType=1 );
    }
}

.content {
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    background: rgb(238,242,245);
    background: -moz-linear-gradient(top, rgba(238,242,245,1) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top, rgba(238,242,245,1) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(238,242,245,1) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eef2f5', endColorstr='#ffffff',GradientType=0 );
    @media screen and (max-width: 650px) {
        padding-top: 50px;
    }
    .top-wave {
        width: 100%;
        height: 365px;
        position: absolute;
        top: -265px;
        overflow: hidden;
        z-index: 0;
        pointer-events: none;
        path {
            fill: #eff3f6;
            height: 100%;
        }
    }
    section {
        padding: 50px 0px;
        @media screen and (max-width: 1050px) {
            padding: 40px 0px;
        }
        @media screen and (max-width: 950px) {
            padding: 30px 0px;
        }
        @media screen and (max-width: 700px) {
            padding: 20px 0px;
        }
    }
    h2 {
        font-size: 3.6rem;
        font-weight: 700;
        line-height: 1;
        @media screen and (max-width: 1450px) {
            font-size: 3rem;
        }
        @media screen and (max-width: 1050px) {
            font-size: 2.8rem;
        }
    }
    .pre-nadpis {
        color: @color-green;
        font-weight: 700;
        font-size: 1.3rem;
    }
}
#rent, #kontakt, #storage, #poledni-menu {
    position: relative;
    .inner-content {
        .clear;
        position: relative;
    }
    @media screen and (max-width: 650px) {
        padding-top: 70px;
    }
    .main {
        width: 67%;
        float: left;
        padding-right: 35px;
        box-sizing: border-box;
        @media screen and (max-width: 700px) {
            width: 100%;
            float: none;
            padding-right: 0px;
        }
        .text {
            font-size: 1.3rem;
            padding-top: 20px;
            line-height: 1.3;
            p {
                padding: 8px 0px;
            }
            .header {
                font-size: 1.6rem;
                font-weight: 700;
                padding-top: 20px;
            }
            .pre-nadpis {
                line-height: 1;
                padding-top: 10px;
            }
        }
        .article-stamp {
            padding-top: 30px;
            text-align: center;
            position: relative;
            .stamp-wrap {
                display: inline-block;
                position: relative;
                a {
                    display: inline-block;
                }
                .email {
                    position: absolute;
                    top: calc(~"50% - 26px");
                    height: 40px;
                    width: 100%;
                    left: 0px;
                }
                .phone {
                    position: absolute;
                    bottom: 15px;
                    height: 70px;
                    left: 50%;
                    right: 0px;
                }
                img {
                    max-width: 250px;
                    width: 100%;
                }
            }
        }
    }
    .points {
        width: 30%;
        padding-left: 80px;
        box-sizing: border-box;
        float: left;
        padding-top: 50px;
        @media screen and (max-width: 1150px) {
            padding-left: 0px;
        }
        @media screen and (max-width: 700px) {
            width: 100%;
            float: none;
            padding-top: 20px;
        }
        ul {
            @media screen and (max-width: 700px) {
                .clear;
            }
            @media screen and (max-width: 500px) {
                justify-content: flex-start;
            }
            li {
                @media screen and (max-width: 700px) {
                    width: 33%;
                    float: left;
                }
                @media screen and (max-width: 500px) {
                    width: 50%;
                }
                a {
                    box-sizing: border-box;
                    align-items: center;
                    padding: 10px 0px;
                    .flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    @media screen and (max-width: 700px) {
                        padding: 10px;
                    }
                    .circle {
                        width: 65px;
                        height: 65px;
                        background-repeat: no-repeat;
                        background-size: 40px auto;
                        background-position: center;
                        background-color: @color-green;
                        box-sizing: border-box;
                        border-radius: 100%;
                        display: block;
                        float: left;
                        @media screen and (max-width: 800px) {
                            width: 50px;
                            height: 50px;
                            background-size: 25px auto;
                        }
                        @media screen and (max-width: 400px) {
                            width: 40px;
                            height: 40px;
                            background-size: 22px auto;
                        }
                    }
                    .point-text {
                        font-size: 1.3rem;
                        color: @color-green;
                        font-weight: 700;
                        padding-left: 15px;
                        display: inline-block;
                        line-height: 1.1;
                        max-width: 150px;
                        float: right;
                        width: calc(~"100% - 65px");
                        box-sizing: border-box;
                        @media screen and (max-width: 800px) {
                            padding-left: 10px;
                            width: calc(~"100% - 50px");
                            font-size: 1.2rem;
                        }
                        @media screen and (max-width: 400px) {
                            width: calc(~"100% - 40px");
                            font-size: 1.1rem;
                        }
                    }
                }
            }
        }
    }
    .floaty-object {
        position: absolute;
        top: -250px; right: -150px;
        @media screen and (max-width: 1850px) {
            right: 10px;
        }
        @media screen and (max-width: 800px) {
            right: 0px;
            top: -200px;
            text-align: center;
        }
        .wrap {
            width: 300px;
            height: 180px;
            display: inline-flex;
            align-items: flex-end;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 15px;
            margin-left: 10px;
            overflow: hidden;
            @media screen and (max-width: 800px) {
                width: 250px;
                height: 150px;
                margin: 0px 10px;
                text-align: left;
            }
            @media screen and (max-width: 650px) {
                width: 45%;
                vertical-align: top;
            }
            @media screen and (max-width: 500px) {
                margin: 0px 2px;
            }
            .text-area {
                width: 100%;
                &:after {
                    background-color: @color-green;
                    content: '';
                    position: absolute;
                    left: -500px;
                    top: -20px;
                    bottom: 0px;
                    right: -50px;
                    border-top-left-radius: 50%;
                    border-top-right-radius: 50%;
                    z-index: 0;
                    opacity: 0.7;
                    transition: opacity 0.2s ease;
                }
                .text {
                    position: relative;
                    z-index: 10;
                    font-weight: 700;
                    line-height: 1;
                    max-width: 300px;
                    font-size: 1rem;
                    div {
                        display: inline-block;
                    }
                }
                .text2 {
                    position: relative;
                    z-index: 10;
                    font-weight: 700;
                    line-height: 1;
                    max-width: 300px;
                    font-size: 0.9rem;
                    padding-top: 4px;
                }
                padding: 10px 20px;
                box-sizing: border-box;
                position: relative;
                font-size: 1.9rem;
                color: #fff;
                text-transform: uppercase;
                @media screen and (max-width: 500px) {
                    padding: 6px;
                    font-size: 1.3rem;
                }
            }
            &:hover {
                .text-area {
                    &:after {opacity: 1;}
                }
            }
        }
    }
}

#eu-projects {
    padding-top: 40px;
    .inner-content {
        .clear;
    }
    .eu-project, .eu-project-2 {
        width: 48%;
        box-sizing: border-box;
        float: left;
        display: block;
        @media screen and (max-width: 900px) {
            float: unset;
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }
        hr {
            height: 30px;
            background-color: #0646a5;
            margin: 0;
        }
        .project-content-bottom {
            background-color: white;
            padding: 25px 10px 35px 10px;
            .clear;
            .eu {
                text-align: left;
                padding-top: 10px;
                float: left;
                width: 60%;
                box-sizing: border-box;
                @media screen and (max-width: 500px) {
                    float: unset;
                    width: 100%;
                }
                img {
                    max-width: 130px;
                    display: inline-block;
                }
                .text {
                    font-size: 16px;
                    color: black;
                    line-height: 1.3;
                    @media screen and (max-width: 1250px) {
                        font-size: 15px;
                    }
                }
            }
            .mpo {
                float: left;
                width: 40%;
                padding-top: 10px;
                padding-left: 15px;
                box-sizing: border-box;
                img {max-width: 220px;}
                @media screen and (max-width: 1250px) {
                    padding-left: 5px;
                }
                @media screen and (max-width: 500px) {
                    float: unset;
                    width: 100%;
                    padding-left: 0;
                    padding-top: 2rem;
                }
            }
        }
        .project-content {
            background-color: white;
            padding: 25px 10px 35px 10px;
            height: 240px;
            box-sizing: border-box;
            @media screen and (max-width: 1100px) {
                height: 270px;
            }
            @media screen and (max-width: 900px) {
                height: auto;
                padding: 15px 10px;
            }
            .small {
                font-size: 15px;
                color: #0646a5;
            }
            .main-text {
                color: #0646a5;
                font-size: 18px;
                font-weight: 500;
                line-height: 1.3;
                @media screen and (max-width: 1250px) {
                    font-size: 17px;
                }
                @media screen and (max-width: 400px) {
                    font-size: 16px;
                }
                .suffix {
                    font-size: 15px;
                    padding-top: 10px;
                }
            }
            .black-text {
                color: black;
                font-size: 13px;
                padding-top: 10px;
            }
        }
    }
    .eu-project-2 {
        float: right;
        @media screen and (max-width: 900px) {
            float: unset;
            margin-top: 2rem;
        }
    }
}

#kontakt {}

#poledni-menu {
    h3 {
        padding: 10px 0px 10px 0px;
        font-size: 1.7rem;
    }
    .next-week {
        margin-top: 40px;
        margin-bottom: 20px;
        height: 6px;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
        @media screen and (max-width: 700px) {
            margin-top: 20px;
            margin-bottom: 10px;
        }
        @media screen and (max-width: 400px) {
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }
    .text {
        p {
            margin-bottom: 10px;
        }
    }
    .points {
        text-align: center;
        line-height: 1.2;
        padding-left: 10px;
        .more-info {
            font-size: 1.4rem;
            font-weight: 500;
            margin-bottom: 10px;
            a {
                font-weight: bold;
            }
        }
        .note {
            margin-bottom: 10px;
            font-size: 1.1rem;
        }
        .minifooter {
            padding-top: 5px;
            font-size: 1.3rem;
            a {
                display: block;
                font-weight: 600;
            }
            .prani {
                padding-top: 25px;
            }
        }
    }
    .menu-section {
        padding: 20px 10px;
        padding-right: 15%;
        background-color: white;
        box-shadow: rgba(163, 163, 163, 0.2) 0px 0px 10px 5px;
        margin-bottom: 20px;
        border-radius: 5px;
        &.next {
            background-color: #f2f2f2;
        }
        @media screen and (max-width: 1100px) {
            padding: 15px 10px;
            margin-bottom: 15px;
        }
        @media screen and (max-width: 950px) {
            padding: 12px 8px;
            margin-bottom: 10px;
        }
        .day {
            font-size: 2.2rem;
            font-weight: 600;
            padding: 5px 10px;
            border-radius: 4px;
            @media screen and (max-width: 1100px) {
                padding: 5px 10px;
            }
            @media screen and (max-width: 650px) {
                padding: 4px 8px;
            }
        }
        .items {
            padding-left: 10px;
        }
        .meal-type {
            margin-top: 10px;
            font-size: 1.4rem;
            font-weight: 500;
            border-bottom: 2px rgba(0,0,0,0.4) solid;
        }
        .meal {
            position: relative;
            margin-top: 4px;
            padding: 4px 15px;
            padding-right: 100px;
            border-radius: 4px;
            @media screen and (max-width: 1110px) {
                padding: 4px 10px;
                padding-right: 90px;
            }
            @media screen and (max-width: 950px) {
                padding: 4px 8px;
                padding-right: 70px;
            }
            @media screen and (max-width: 450px) {
                padding: 4px 5px;
                padding-right: 55px;
            }
            &:nth-of-type(2n) {
                border-top: 2px rgba(0, 0, 0, 0.07) solid;
                border-bottom: 2px rgba(0, 0, 0, 0.07) solid;
            }
            &:last-of-type {
                border: 0;
            }
            .name {
                font-weight: 600;
                font-size: 1.2rem;
            }
            .price {
                position: absolute;
                top: 0px;
                right: 0px;
                bottom: 0px;
                padding: 5px 10px;
                box-sizing: border-box;
                width: 100%; height: 100%;
                width: 100px;
                text-align: right;
                font-size: 1.1rem;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
            p {
                font-size: 1.1rem;
                font-style: italic;
            }
        }
        .soup {
            border: 0;
        }
    }
}

#services {
    z-index: 50;
    position: relative;
    .services-list {
        .flex;
        .flex-wrap(wrap);
        justify-content: center;
        padding: 30px 0px;
        align-items: stretch;
        .clear;
        .service {
            width: 22%;
            box-sizing: border-box;
            padding: 20px;
            float: left;
            @media screen and (max-width: 1150px) {
                width: 24%;
                padding: 10px;
            }
            @media screen and (max-width: 900px) {
                width: 33.33%
            }
            @media screen and (max-width: 550px) {
                width: 50%;
            }
            @media screen and (max-width: 420px) {
                width: 100%;
                padding: 5px;
            }
            .inside {
                -webkit-box-shadow: 0px 0px 10px 5px rgba(163,163,163,0.2);
                -moz-box-shadow: 0px 0px 10px 5px rgba(163,163,163,0.2);
                box-shadow: 0px 0px 10px 5px rgba(163,163,163,0.2);
                border-radius: 10px;
                padding: 20px;
                height: 100%;
                box-sizing: border-box;
                background-color: #fff;
                @media screen and (max-width: 900px) {
                    padding: 10px;
                }
                @media screen and (max-width: 550px) {
                    padding: 5px;
                }
                .icon {
                    width: 100%;
                    max-width: 100%;
                    text-align: center;
                    padding: 0px 5px;
                    box-sizing: border-box;
                    @media screen and (max-width: 900px) {
                        height: 50px;
                        img {
                            max-height: 100%;
                        }
                    }
                }
                .service-name {
                    font-size: 1.7rem;
                    font-weight: 700;
                    text-align: center;
                    line-height: 1.1;
                    padding: 5px;
                    box-sizing: border-box;
                    @media screen and (max-width: 1450px) {
                        font-size: 1.5rem;
                    }
                    @media screen and (max-width: 550px) {
                        font-size: 1.4rem;
                    }
                }
                .text {
                    line-height: 1.1;
                    font-size: 1.2rem;
                    font-weight: 400;
                    text-align: center;
                    padding: 5px;
                    box-sizing: border-box;
                    @media screen and (max-width: 1150px) {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
}

#virtual-office {
    background-color: #ebf0f4;
    background-image: url('../assets/background-people.png');
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    position: relative;
    margin-top: -100px;
    // margin-bottom: -70px;
    padding-bottom: 100px;
    @media screen and (max-width: 950px) {
        // margin-bottom: -120px;
    }
    .top-wave {
        width: 100%;
        height: 310px;
        position: absolute;
        top: -310px;
        overflow: hidden;
        z-index: 0;
        pointer-events: none;
        path {
            fill: #ebf0f4;
            height: 100%;
        }
    }
    .inner-content {
        position: relative;
        padding-top: 100px;
    }
    .section {
        padding: 20px 0px;
        max-width: 50%;
        @media screen and (max-width: 700px) {
            max-width: calc(~"100% - 160px");
        }
        @media screen and (max-width: 500px) {
            max-width: 100%;
            padding: 10px;
        }
        .text {
            font-size: 1.1rem;
            font-weight: 300;
        }
        h3 {
            font-size: 3.2rem;
            font-weight: 700;
            line-height: 1;
            padding-bottom: 20px;
        }
        .more {
            a {
                display: block;
                color: @color-green;
                font-weight: 700;
                font-size: 1.3rem;
            }
        }
    }
    .stamp {
        background-image: url('../assets/stamp.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        width: 297px; height: 266px;
        top: 10px; right: -50px;
        display: block;
        z-index: 51;
        @media screen and (max-width: 1550px) {
            right: 20px;
        }
        @media screen and (max-width: 700px) {
            width: 150px;
            height: 150px;
        }
        @media screen and (max-width: 500px) {
            width: 100px; height: 100px;
        }
        @media screen and (max-width: 400px) {
            top: 30px;
        }
    }
}

#nadfooter {
    background: #009719;
    background: -moz-linear-gradient(left, #009719 0%, #02c522 100%);
    background: -webkit-linear-gradient(left, #009719 0%,#02c522 100%);
    background: linear-gradient(to right, #009719 0%,#02c522 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009719', endColorstr='#02c522',GradientType=1 );
    position: relative;
    padding-top: 10px;
    padding-bottom: 20px;
    .svg-container {
        position: absolute;
        top: 1px;
        left: -10px; right: 0px;
        height: 0px;
        pointer-events: none;
        .footer-wave {
            width: 100%;
            position: absolute;
            bottom: 0px;
            display: block;
        }
    }
    .svg-container2 {
        overflow: hidden;
        position: absolute;
        top: 99%;
        left: 0px; right: 0px;
        width: 100%;
        height: 170px;
        pointer-events: none;
        z-index: 1;
        .footer-wave {
            width: 100%;
        }
    }
    .inner-content {
        position: relative;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .top-wave {
        width: 100%;
        height: 310px;
        position: absolute;
        top: -310px;
        overflow: hidden;
        z-index: 0;
        pointer-events: none;
        path {
            height: 100%;
            width: 100%;
        }
    }
    .locality {
        font-size: 2.2rem;
        color: #fff;
        font-weight: 700;
        padding-top: 20px;
        padding-right: 60px;
        display: inline-block;
        vertical-align: sub;
        width: 290px;
        box-sizing: border-box;
        @media screen and (max-width: 1000px) {
            font-size: 2rem;
        }
        @media screen and (max-width: 850px) {
            width: 100%;
            max-width: unset;
            box-sizing: border-box;
            padding-top: 0px;
        }
        @media screen and (max-width: 600px) {
            padding: 10px 0px;
            font-size: 1.9rem;
            h2 {
                line-height: 1;
            }
        }
    }
    .wrap {
        display: inline-block;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(~"100% - 295px");
        .clear;
        vertical-align: middle;
        @media screen and (max-width: 1000px) {
            padding: 15px 0px;
        }
        @media screen and (max-width: 850px) {
            width: 100%;
        }
        @media screen and (max-width: 600px) {
            padding: 15px 0px;
        }
        @media screen and (max-width: 550px) {
            justify-content: space-between;
            align-items: flex-start;
        }
        @media screen and (max-width: 500px) {
            .flex-wrap(wrap);
        }
        .section {
            padding-right: 100px;
            box-sizing: border-box;
            float: left;
            @media screen and (max-width: 1300px) {
                padding-right: 60px;
            }
            @media screen and (max-width: 1050px) {
                padding-right: 35px;
            }
            @media screen and (max-width: 900px) {
                padding-right: 25px;
            }
            @media screen and (max-width: 600px) {
                padding-right: 20px;
            }
            @media screen and (max-width: 550px) {
                padding: 0px 5px;
                text-align: center;
            }
            @media screen and (max-width: 500px) {
                width: 50%;
                max-width: unset;
                padding-bottom: 10px;
            }
            .distance {
                color: #383838;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 1;
            }
            .text {
                color: #fff;
                font-weight: 700;
                font-size: 1.4rem;
                @media screen and (max-width: 550px) {
                    line-height: 1.1;
                }
            }
        }
    }
    &.subpage {
        padding: 60px 0px;
        .wrap {
            display: none;
        }
    }
}

footer {
    position: relative;
    line-height: 1.2;
    .footer-wrap {
        position: relative;
        padding-top: 55px;
        .left {
            width: 420px;
            padding-top: 80px;
            padding-bottom: 100px;
            padding-right: 20px;
            padding-left: 0px;
            box-sizing: border-box;
            @media screen and (max-width: 1400px) {
                width: 35%;
            }
            @media screen and (max-width: 700px) {
                width: 100%;
                padding: 20px;
            }
            .text {
                padding: 20px 0px;
            }
            .address {
                line-height: 1.4;
                padding: 20px 0px;
                font-weight: 600;
            }
        }
    }
    .map {
        position: absolute;
        top: 0px; bottom: 0px; right: 0px;
        width: calc(~"100% - 700px");
        background-image: url('../assets/map.jpg');
        background-position: left center;
        background-repeat: no-repeat;
        display: block;
        @media screen and (min-width: 1950px) {
            width: 1250px;
        }
        @media screen and (max-width: 1400px) {
            width: 65%;
        }
        @media screen and (max-width: 850px) {
            background-position: left -50px center;
        }
        @media screen and (max-width: 700px) {
            width: 100%;
            position: relative;
            height: 250px;
        }
        @media screen and (max-width: 500px) {
            background-position: left -150px center;
        }
    }
    .inner-content {
        position: relative;
    }
    
    .podfooter {
        position: relative;
        .svg-container {
            overflow: hidden;
            position: absolute;
            bottom: 100%;
            left: 0px;
            right: 0px;
            width: 100%;
            height: 121px;
            pointer-events: none;
            padding-bottom: 30px;
            transform: scaleY(0.4);
            transform-origin: bottom;
            .footer-wave {
                width: 100%;
            }
            @media screen and (max-width: 1350px) {
                height: 120px;
            }
            @media screen and (max-width: 1100px) {
                height: 90px;
            }
            @media screen and (max-width: 950px) {
                height: 75px;
            }
            @media screen and (max-width: 800px) {
                height: 65px;
            }
            @media screen and (max-width: 700px) {
                height: 55px;
            }
            @media screen and (max-width: 400px) {
                height: 40px;
            }
        }
        display: block;
        width: 100%;
        clear: both;
        padding-top: 30px;
        background: #009719;
        background: -moz-linear-gradient(left, #009719 0%, #02c522 100%);
        background: -webkit-linear-gradient(left, #009719 0%,#02c522 100%);
        background: linear-gradient(to right, #009719 0%,#02c522 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#009719', endColorstr='#02c522',GradientType=1 );
        color: #fff;
        .line {
            text-align: center;
        }
        .line2 {
            .flex;
            align-items: center;
            justify-content: center;
            padding: 15px 0px;
            .clear;
            a {
                float: left;
                margin: 0px 5px;
                &:hover {
                    color: #000;
                }
            }
            .left {
                padding: 0px 10px;
                border-right: 1px solid #fff;
            }
            .right {
                padding: 0px 10px;
            }
        }
        .line3 {
            text-align: right;
            font-size: 0.8rem;
            padding-bottom: 10px;
            a {
                padding-left: 5px;
                vertical-align: sub;
            }
            img {
                // filter: invert(100%);
            }
        }
        @media screen and (max-width: 410px) {
            padding-bottom: 80px;
        }
    }
}

#galerie {
    padding-top: 50px;
    position: relative;
    background-color: #ebf0f4;
    margin-bottom: -80px;
    padding-bottom: 0px;
    .inner-content.fullWidth {
        max-width: 3000px;
        padding: 0px;
    }
    .galerie-wrap {
        position: relative;
        padding-top: 14px;
        .galerie-items {
            width: 100%;
            .clear;
            .image {
                position: relative;
                float: left;
                .inner-overlay {
                    position: absolute;
                    top: 0; left: 0px;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    opacity: 0;
                    font-size: 5rem;
                    color: white;
                    text-align: center;
                    transition: all 0.3s ease;
                    overflow: hidden;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    background-color: rgba(0,0,0,0.7);
                    padding-top: 15%;
                    .flex;
                    div {
                        transition: all 0.4s ease 0.1s;
                        opacity: 0;
                        transform: translate(150px,150px);
                        display: inline-block;
                        .fa {
                            font-size: 4rem;
                        }
                    }
                }
                &:hover {
                    .inner-overlay {
                        opacity: 0.7;
                        div {
                            opacity: 1;
                            transform: translate(0px,-25px);
                        }
                    }
                }
                width: 25%;
                img {
                    display: block;
                }
                @media screen and (max-width: 900px) {
                    &.no-mobile {
                        display: none;
                    }
                    width: 33.33%;
                }
                @media screen and (max-width: 600px) {
                    width: 50%;
                }
                @media screen and (max-width: 450px) {
                    width: 100%;
                }
            }
        }
    }
    .svg-container {
        position: absolute;
        left: 0px; right: 0px;
        bottom: 0px;
        width: 100%;
        pointer-events: none;
        height: auto;
        .footer-wave {
            width: 100%;
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: auto;
            display: block;
        }
    }
    .svg-container2 {
        position: absolute;
        left: 0px; right: 0px;
        top: 10px;
        width: 100%;
        pointer-events: none;
        height: auto;
        z-index: 5;
        .footer-wave {
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: auto;
            display: block;
        }
    }
}

.back-up {
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
    position: fixed;
    bottom: 30px; right: 10px;
    background-color: @color-green;
    border-radius: 100%;
    width: 80px; height: 80px;
    font-size: 3rem;
    line-height: 70px;
    text-align: center;
    color: white;
    z-index: 95;
    box-sizing: border-box;
    border: 5px white solid;
    cursor: pointer;
    &.show {
        visibility: visible;
        opacity: 1;
    }
    @media screen and (max-width: 750px) {
        width: 50px; height: 50px;
        line-height: 40px;
        font-size: 2.4rem;
    }
}

@media print {
  html, body {
    width: 300mm;
  }
}

// smartphoto override
.smartphoto-arrows li {
    width: 70px;
    height: 160px;
    margin-top: -80px;
}
.smartphoto-arrow-left {
    background-color: transparent;
    left: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    a {
        background-position: center;
        background-repeat: no-repeat;
        padding: 40px 10px;
        box-sizing: border-box;
        background-size: 40px auto;
        display: block;
    }
    @media screen and (max-width: 650px) {
        left: 30px;
    }
    @media screen and (max-width: 450px) {
        left: 20px;
    }
}
.smartphoto-arrow-right {
    background-color: transparent;
    right: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    a {
        background-position: center;
        background-repeat: no-repeat;
        padding: 40px 10px;
        box-sizing: border-box;
        display: block;
        background-size: 40px auto;
    }
    @media screen and (max-width: 650px) {
        right: 30px;
    }
    @media screen and (max-width: 450px) {
    right: 20px;
    }
}
.smartphoto-dismiss {
    right: 15px;
    width: 25px; height: 25px;
}